<style scoped lang='scss'>
#clause {
}
</style>

<template>
  <div id="clause">
    <div class="width rullMain">
      <div class="title textCenter weight">使用条款</div>
      <p>
        本使用条款和条件（“使用条款”）适用深圳果果慧科技有限公司（简称“果果慧科技”、“本公司”或“我们”）的官方网站（网址为：
        http://www.aifrutech.com/，简称“本网站”）。本网站是果果慧科技及其许可人的财产。您使用本网站，即表示您同意本使用条款；如果您不同意，请勿使用本网站。
      </p>
      <p>
        果果慧科技保留自行决定在任何时候变更、修改、增加或删除本使用条款部分内容的权利。我们会以适当方式通知您。您在变更信息发布之后继续使用本网站，即表示您同意且接受该等变更。若您遵守本使用条款，果果慧科技即授予您一项个人、非排他性、不可转让、有限的权利以登录并使用本网站。
      </p>
      <p class="weight">内容</p>
      <p>
        本网站包含的所有文字、图表、用户界面、可视界面、图片、商标、标识、声音、音乐、美术作品及计算机编码（合称为“内容”），包括但不限于该等内容的设计、结构、选择、协调、表达、“界面外观”及排序等，均属果果慧科技所有或被许可给果果慧科技使用，且受著作权法、专利法、商标法以及各类其他知识产权法等的保护。
      </p>
      <p>
        除本使用条款明确规定外，未经果果慧科技事先明确书面同意，您不得为出版或发行或为任何其他商业目的以任何方式（包括“镜像”）将本网站的任何部分及任何内容复制、再制、重印、上传、发布、公开展示、编码、翻译、传输或散布至任何其他计算机、服务器、网站或其他媒介。
      </p>
      <p class="weight">您对本网站的使用</p>
      <p>
        您不可使用任何“深层链接”、“抓取页面”、“机器人”、“蜘蛛”或其他自动装置、程序、算法、方法或者任何相似或相同的手动程序，以访问、取得、复制或监测本网站任何部分或任何内容，或以任何方式复制或规避本网站或任何内容的导航结构或介绍，通过任何非本网站提供的方式，取得或试图取得任何资料、文件或信息。果果慧科技有权禁止任何该等行为。
      </p>
      <p>
        您不可试图通过非法侵入、“破解”密码或任何其他非法方式，未经授权访问本网站的任何部分或功能，或者链接至本网站或任何果果慧科技服务器的任何其他系统或网络，或者未经授权取得本网站提供的任何服务。
      </p>
      <p>
        您不可探查、扫描或测试本网站或链接至本网站之任何网络的弱点，亦不可违反本网站之任何网络的安全或认证措施。
      </p>
      <p>
        您同意不使用任何设备、软件或程序，干扰或试图干扰本网站的正常运行或于本网站进行的任何交易，或干扰或试图干扰他人使用本网站。
      </p>
      <p>
        您不可伪造信头或以其他方式操控识别功能，以伪造您通过本网站提交给果果慧科技的任何信息或传输内容的来源，或者伪造本网站提供的任何服务的来源。您不可假冒他人、或假冒代表他人，或模仿任何其他个人或实体。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "clause",
  components: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {
    window.scrollTo({top: 0,left: 0});
  },
  methods: {},
};
</script>
